import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import Theme from '../components/theme'
import {
  SEO,
  Layout,
  // SectionHero,
  SectionBanner,
  SectionRecentPosts,
  SectionLogoCloud,
  SectionText,
} from '../components'
import page from '../data/pages/impactinvestment.yml'
import { logos } from '../data/static/logos'

const H40Page = (): React.ReactElement => {
  return (
    <>
      <SEO title={page?.title} />
      <Theme>
        <div className="fixed z-0 w-full h-full top-0 left-0 opacity-10">
          <StaticImage
            src="../images/fish.jpg"
            alt="Fische"
            objectFit="cover"
            className="w-full h-full select-none"
            draggable={false}
          />
        </div>
        <Layout>
          {/* <SectionHero
            title="Impact Investment"
            titleTag="h1"
            subtitle=""
            text="We are thrilled that you are interested in investing into the planet’s future. We’d be even more happy to directly talk to you about options to invest into the future of payment. Don’t hesitate to contact us."
            textSize="medium"
            actionLinks={[
              {
                label: 'Make an appointment',
                url: 'termin-investoren',
              },
              {
                url: '../2021_11_pitch_slushhelsinki-_V4_0_0_final-min.pdf',
                label: 'Download Pitch-Deck',
                variant: 'link',
              },
            ]}
            image={
              <StaticImage
                src="../images/socialcashback.png"
                alt="Impact Investment Tree"
              />
            }
          /> */}

          <SectionBanner
            title="Talk to me"
            text="My name is **[Mathias Born](/de/unternehmen#team)** and I am one of the founders of payactive. We are thrilled that you are interested in investing into the planet’s future. We’d be even more happy to directly talk to you about options to invest into the future of payment. Don’t hesitate to contact us."
            image={
              <StaticImage
                src="../images/mathiaswithstars.png"
                alt="Person mit Glitzer"
                style={{ maxWidth: 450 }}
                // className="md:w-"
              />
            }
            actionLinks={[
              {
                label: 'Make an appointment',
                url: 'termin-investoren',
              },
              {
                label: 'request information',
                url: 'mailto:hello@payactive.eu?subject=Request for information',
                variant: 'link',
              },
            ]}
          />

          <SectionText title="payactive in Action" containerClass="pt-14" />
          <SectionRecentPosts />
          <SectionText
            title="Partners"
            subtitle="Companies that help us grow"
            containerClass="pt-14"
            centered
          />
          <SectionLogoCloud data={logos} />
        </Layout>
      </Theme>
    </>
  )
}

export default H40Page
